
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.request-overview {
    position: relative;
}
.requests-frequency_button {
    position: absolute;
    top: 1em;
    right: 1em;
}
